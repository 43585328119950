@import '../../styles/variables';

.forecastHeaderWrapper {
  padding: 24px 0 0;
  width: 100%;
}

.headerDetails {
  flex: 1 1 50%;

  @media (min-width: $tabletLg) {
    padding-right: 32px;
  }
}

.conditionsBlock {
  width: 8px;
  border-radius: 99px;
  margin: auto 10px auto 0;
  height: 100%;
}

.tab {
  padding: 12px 0;
  min-width: 0px;
  margin-right: 40px;
  font-weight: 700;

  // TODO: See if this can be done in theme and change there
  &:hover,
  &:focus-visible {
    color: $product-secondary;
  }
}

.contentCard {
  margin: 10px 0 40px;

  @media (min-width: $tabletLg) {
    margin: 20px 0 40px;
  }
}
