@import '../../../../../../styles/_variables.scss';

.renderSpot {
  display: flex;
  align-items: center;

  a {
    color: $textPrimary;
    margin-right: 5px;
    outline: none;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 0;

    &:hover,
    &:focus-visible {
      color: $brandPrimary;
    }
    @media(min-width: $tabletLg) {
      font-size: 14px;
      padding: 0;
    }
  }

  .iconWrapper {
    margin-left: 4px;
    width: 45px;
  }

  .icon {
    height: 12px;

    g {
      fill: $gray30;
    }
  }

  :global {
    .quiver-surf-conditions {
      font-size: 0;
      padding: 0;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin-right: 5px;
    }
  }
}

.renderSpot--lastOfGroup {
  margin-bottom: 10px;
}

.insightsIcon svg {
  height: 14px;
  width: 14px;
}
