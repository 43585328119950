@import '../../../styles/variables';

.sl-spot-page {
  background-color: $grayLight;
}

.sl-spot-page__loading {
  background-color: $white;
}

.sl-spot-page__below-the-fold {
  background-color: color('light-blue', 5);
  position: relative;

  .quiver-content-container {
    justify-content: left;
  }

  .sl-nearby-buoys-wrapper {
    width: 100%;
  }

  .sl-nearby-buoys-wrapper .sl-nearby-buoys {
    padding-top: 0;
    margin-top: 16px;
  }

  .quiver-content-container {
    > div {
      margin-top: 0;
      margin-bottom: 16px;
      &:nth-child(1) {
        margin-top: 16px;
      }
    }
  }

  /* Remove the extra margin above the forecast graphs in V2 with the new Current Conditions */
  .quiver-content-container {
    z-index: 2;
    position: relative;

    > div {
      &:nth-child(1) {
        margin-top: 0px;
      }
    }
  }

  &__sl-spot-page-v2 {
    background-color: $white;
    position: relative;

    &::after {
      background: linear-gradient(180deg, transparent, $white);
    }
  }

  &__container {
    position: relative;
    z-index: 2;
  }

  &__lower-content {
    background-color: $grayLight;
    position: relative;

    > * {
      z-index: 2;
      position: relative;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      background: linear-gradient(180deg, $white, $grayLight);
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__bottom-ad {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 16px;
  }

  .premium-feature-cta-item,
  .premium-feature-cta-item:last-child {
    margin: 8px 0;
  }
}

.sl-spot-page__ad-container {
  background-color: $background;
}

@media (max-width: $medium-mobile) {
  .sl-spot-page__below-the-fold {
    background-color: color('light-blue', 5);
  }
  .sl-spot-page__below-the-fold__sl-spot-page-v2 {
    background-color: $white;
  }
}

@media (min-width: $tabletLg) {
  .sl-spot-page__below-the-fold {
    .premium-feature-cta-item {
      flex: 1;
      margin: 0 8px 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  .sl-spot-page__below-the-fold .sl-nearby-buoys-wrapper .sl-nearby-buoys {
    margin-top: 24px;
  }
}
