@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.newsIcon {
  fill: color('gray', 55);
  height: 10px;
  width: 12px;

  rect {
    height: 14;
    width: 14;
  }
}
