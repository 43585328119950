@import '../../../../../../../../styles/_variables.scss';

.result {
  span {
    color: $textPrimary;
  }

  highlight,
  mark {
    background-color: transparent;
    color: $brandPrimary;
  }

  svg {
    margin-right: 6px;
    position: relative;
    width: 13px;
  }

  :global {
    .quiver-cam-icon {
      height: 12px;
      top: 6px;
    }

    .quiver-multi-cam-icon {
      height: 12px;
      top: 6px;
    }

    .quiver-forecast-icon {
      margin-right: 3px;
      top: 1px;
      width: 16px;
    }

    .quiver-location-icon {
      margin-right: 3px;
      top: 5px;
      width: 16px;
    }

    .quiver-news-icon {
      height: 13px;
      margin-right: 8px;
      top: 6px;
    }

    .quiver-travel-icon {
      margin-right: 3px;
      top: 0;
      width: 16px;
    }

    .cameraInsightsIcon {
      height: 14px;
      width: 14px;
      margin-top: 5px;
    }
  }
}

.link {
  display: flex;
  min-width: 0;
  padding: 8px 4px;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    background: $brandPrimary;
    border-radius: 4px;

    .subtitle
    .title,
    highlight,
    mark,
    span {
      color: $white;
    }

    svg,
    g,
    path {
      fill: $white;
    }
  }

  @media(min-width: $large-mobile) {
    padding: 4px;
  }
}

.title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title--news {
  white-space: normal;
}

.subtitle {
  color: $brandPrimary;
  padding-left: 8px;
}
