.errorMessage {
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.icon {
  margin-right: 20px;
}

.subMessage {
  margin-top: 10px;
}
