@import '../_variables.scss';

.sl-spot-list-siblings {
  &__type {
    h5 {
      font-size: 14px;
      line-height: 18px;
      margin: 8px 0 4px;
      font-family: $source-sans-pro;
      letter-spacing: .5px;
      color: color('gray', 90);
    }
    span {
      a {
        @include small-paragraph;
        color: color('gray', 55);
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $gray-blue-dark;
        }
      }
      &:after {
        content: '';
        padding-right: 3px;
      }
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  &__more {
    @include small-paragraph;
    padding-left: 6px;
    color: color('gray', 55);
    cursor: pointer;
    position: relative;
    &:before {
      content: "|";
      position: absolute;
      left: 0;
      top: -3px;
    }
    span:hover {
      color: $gray-blue-dark;
    }
    &--hidden {
      display: none;
    }
  }
}
