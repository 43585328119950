@mixin injectResponsiveStyles(
  $container-width,
  $header-height,
  $card-container-width,
  $show-loading-icon,
  $show-load-more-icon
) {
  .infinite-scroll-component {
    padding: 20px 40px 20px 20px;
  }

  .sl-premium-analysis--feed-cards-loader {
    width: 85%;
    clear: both;
    margin: 0 auto;
    img {
      padding: 30px;
      display: $show-loading-icon;
      margin: 0 auto;
    }
    .quiver-button {
      display: $show-load-more-icon;
      margin: 0 auto;
    }
  }

  .sl-premium-analysis--load-more {
    text-align: center;
    color: black;
    display: $show-load-more-icon
  }

  .sl-premium-analysis {
    .quiver-treatment-wrapper {
      width: 100%;
      display: flex;
    }
  }

  .sl-premium-analysis__content {
    width: 100%;
    background: color('white');
    margin-bottom: 20px;
    &__video {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      iframe {
        max-width: 600px;
      }
    }
  }

  .sl-premium-analysis--feed-cards {
    padding: 10px 10px 10px 10px;
    &__cards {
      width: 100%;
      margin: 10px auto;
      padding: 0;
      position: relative;
      &__mobile {
        padding: 5px;
        background: color('light-blue', 5);
        @if $card-container-width != 100% {
          display: none;
        }
        &__card {
          margin-bottom: 30px;
          width: 100%;
        }
      }
      &__desktop {
        padding: 10px;
        background: color('light-blue', 5);
      }
    }
  }
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 0px) {
  @include injectResponsiveStyles(
    $container-width: 320px,
    $header-height: 110px,
    $card-container-width: 100%,
    $show-loading-icon: block,
    $show-load-more-icon: none
  );
  .sl-premium-analysis__content {
    &__video {
      padding-bottom: 56.25%;
      min-height: initial;
    }
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  @include injectResponsiveStyles(
    $container-width: 450px,
    $header-height: 110px,
    $card-container-width: 100%,
    $show-loading-icon: block,
    $show-load-more-icon: none
  )
}

/* Custom break for jwplayer */
@media only screen and (min-width : 613px) {
  @include injectResponsiveStyles(
    $container-width: 450px,
    $header-height: 110px,
    $card-container-width: 100%,
    $show-loading-icon: block,
    $show-load-more-icon: none
  );
  .sl-premium-analysis__content {
    &__video {
      padding-bottom: 0;
      min-height: 338px;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  @include injectResponsiveStyles(
    $container-width: 738px,
    $header-height: 136px,
    $card-container-width: 100%,
    $show-loading-icon: block,
    $show-load-more-icon: none
  )
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  @include injectResponsiveStyles(
    $container-width: 962px,
    $header-height: 136px,
    $card-container-width: 100%,
    $show-loading-icon: none,
    $show-load-more-icon: block
  )
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  @include injectResponsiveStyles(
    $container-width: 1176px,
    $header-height: 136px,
    $card-container-width: 100%,
    $show-loading-icon: none,
    $show-load-more-icon: block
  )
}
