@import '../../../../../../styles/_variables.scss';

.addFavorites {
  border-radius: 20px;
  border: 0;
  box-sizing: border-box;
  display: inline-block;
  height: 36px;
  outline: none;
  padding: 6px 18px 6px 16px;
  text-decoration: none;
  transition: background 250ms ease 0s;
  vertical-align: middle;

  &:hover,
  &:focus-visible {
    background-color: $grayLight;

    .label {
      color: $brandSecondary;
    }
  }
}

.wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}

.label {
  color: $brandSecondary;
  padding-left: 8px;
}

.icon {
  height: 16px;
  width: 16px;

  circle,
  path {
    stroke: $brandSecondary;
  }
}
