@import '../containers/routes/mixins';

.sl-child-grid {
  position: relative;
  max-width: $desktop;
  margin: 0 auto 60px;
  padding: 0 10px;
  @media(min-width: $tablet) {
    padding: 0 50px;
  }
  &:before {
    content: ' ';
    display: block;
    border-top: 1px solid color('gray', 40);
    margin: 90px auto;
    width: 60px;
  }
  h3 {
    @include sl-h4;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    padding: 0 0 70px;
    span {
      display: block;
      @media(min-width: $tablet) {
        display: inline-block;
      }
    }
  }
  &__grid {
    position: relative;
    width: 100%;
    height: 440px;
    text-align: center;
    overflow: hidden;
    &--open {
      height: auto;
    }
    &__child {
      position: relative;
      display: block;
      width: 300px;
      height: 160px;
      border: 1px solid color('light-blue', 50);
      box-sizing: border-box;
      margin: 0 auto;
      margin-bottom: 30px;
      background: color('white');
      color: color('light-blue', 50);
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      @media(min-width: $large-mobile) {
        display: inline-block;
        margin: 0 15px 30px;
      }
      &:hover {
        background: color('light-blue', 50);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        color: color('white');
      }
      h6 {
        position: absolute;
        width: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include sl-h6;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: inherit;
        @media(min-width: $large-mobile) {
          font-size: 16px;
        }
        span {
          display: block;
          &:hover {
            color: color('white');
          }
        }
      }
      &--image {
        border: none;
        color: color('white');
      }
    }
  }
  &__more {
    position: relative;
    top: 40px;
    @include sl-h6;
    margin-bottom: 90px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: color('light-blue', 50);
    cursor: pointer;
    @media(min-width: $large-mobile) {
      font-size: 16px;
    }
    &:before {
      position: absolute;
      top: -90px;
      left: 0;
      right: 0;
      content: "";
      height: 50px;
      background: linear-gradient(180deg,hsla(0,0%,100%,0),#fff);
    }
    &--hidden {
      display: none;
    }
    &:hover {
      color: color('light-blue', 70);
    }
  }
}
