@import '../../styles/_variables.scss';

.surfConditions {
  color: $white;
  font-family: $product-font-linear;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 3px 6px;
  text-transform: uppercase;

  &:hover,
  &:focus-visible {
    color: $white;
  }
}

.surfConditions--expired {
  background-color: $inactive;
}
