@import '../../../../../../../../styles/_variables.scss';

.renderCity {
  border-top: 1px solid $grayLight;
  color: $textPrimary;
  padding: 10px 0;

  &:first-of-type {
    border-top: none;
  }

  @media(min-width: $tabletLg) {
    border: none;
    font-size: 14px;
    padding: 0 0 0.4em;
  }
}
