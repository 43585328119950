@import '../../../../../../../../styles/_variables.scss';

.renderGeoname {
  border-bottom: 1px solid $grayLight;
  line-height: 0px;

  @media(min-width: $tabletLg) {
    border: none;
  }
}

.button {
  align-items: center;
  background: none;
  border: none;
  color: $textPrimary;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  line-height: 48px;
  outline: none;
  padding: 0;
  text-align: left;
  width: 100%;

  &:hover,
  &:focus-visible {
    color: $brandPrimary;
    cursor: pointer;
  }

  .chevron {
    stroke-width: 1px;
    stroke: $gray40;
    width: 14px;
  }

  @media(min-width: $tabletLg) {
    display: block;
    font-size: 14px;
    line-height: 24px;
    width: auto;

    .chevron {
      display: none;
    }
  }
}
