@import '../../../../../../../../styles/_variables.scss';

.section {
  padding: 0 0 24px;
  width: 100%;

  @media(min-width: $tablet) {
    min-width: 0;
    padding: 0 16px 24px;
    width: 50%;
  }

  @media(min-width: $tabletLg) {
    min-width: 0;
    padding: 0 16px 24px;
    width: 33%;
  }
}

.section--surf-news {
  @media(min-width: $tabletLg) {
    height: 100%;
  }
}

.title {
  padding: 0 4px 4px;
}

.results {
  list-style: none;
  margin: 0;
  padding: 0 0 4px;

  @media(min-width: $large-mobile) {
    padding-bottom: 2px;
  }
}

.noResults {
  color: $textPrimary;
  padding: 0 4px;
}

.viewAll {
  color: $brandPrimary;
  display: block;
  margin: 5px 0 0 20px;
  padding: 8px 4px;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    outline: 0;
    text-decoration: underline;
  }

  @media(min-width: $large-mobile) {
    padding: 4px;
  }
}
