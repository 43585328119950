$buttonSize: 44px;

.favoriteButton {
  align-items: center;
  background-color: transparent;
  border-radius: $buttonSize;
  display: flex;
  height: $buttonSize;
  justify-content: center;
  margin: 2px 5px;
  min-width: $buttonSize;
  padding: 0;
  width: $buttonSize;
}

.loadingIcon {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
