@import '../../styles/variables';

$min-height-maptiler-container: 370px;

.sl-maptiler-container {
  height: calc(100vh - $site-header-height-mobile - $spot-page-header-height);
  @supports (height: 100svh) {
    height: calc(100svh - $site-header-height-mobile - $spot-page-header-height);
  }
  width: 100%;
  position: relative;

  @media (min-width: $tablet) {
    height: calc(100vh - $site-header-height-tablet - $spot-page-header-height-tablet);
  }

  @media (min-width: $tabletLg) {
    height: calc(100vh - $site-header-height-desktop - $spot-page-header-height-tablet);
  }

  min-height: $min-height-maptiler-container;
}

.sl-maptiler-container-charts-homepage {
  height: calc(100vh - $site-header-height-mobile);
  @supports (height: 100svh) {
    height: calc(100svh - $site-header-height-mobile);
  }
  width: 100%;
  position: relative;

  @media (min-width: $tablet) {
    height: calc(100vh - $site-header-height-tablet);
  }

  @media (min-width: $tabletLg) {
    height: calc(100vh - $site-header-height-desktop);
  }

  min-height: $min-height-maptiler-container;
}

.sl-maptiler-container-native {
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  @media(orientation: landscape) {
    @supports (height: 100svh) {
      min-height: 100svh;
    }
  }
}
