@import './variables';

html, body {
  font-family: $source-sans-pro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 16px;
  line-height: 24px;
  color: color('gray', 80);
}

a {
  color: color('light-blue', 50);

  :hover {
    color: color('light-blue', 70);
  }

  .link-gray {
    color: color('gray', 80);

    :hover {
      color: color('gray', 90);
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $product-font-linear;
  color: color('gray', 90);
}

h1 {
  font-size: 92px;
  line-height: 95px;
}

h2 {
  font-size: 52px;
  line-height: 58px;
}

h3 {
  font-size: 38px;
  line-height: 42px;
}

h4 {
  font-size: 28px;
  line-height: 32px;
}

h5 {
  font-size: 24px;
  line-height: 29px;
}

h6 {
  font-size: 18px;
  line-height: 24px;
}

:root {
  // Set conditions CSS vars
  @each $index, $color in $condition-colors {
    $condition: to-upper-case(str-slice($index, 1, 1)) + str-slice($index, 2);
    --conditions#{$condition}: #{$color};
  };
}
