@import '../../../../../../styles/_variables.scss';

.userIcon {
  stroke: $brandSecondary;
  stroke-width: 2px;
}

.ring {
  fill: $background;
  stroke: $brandSecondary;
}

.ring--premium {
  stroke: $success;
}

.ring--premium-plus {
  stroke: $brand-expressive-bronzing;
}

.ring--alert {
  stroke: $info;
}

.ring--warning {
  stroke: $warning;
}

.ring--error {
  stroke: $error;
}

.notification {
  stroke: $info;
}

.notificationSvg {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
}

.notification--alert {
  fill: $info;
}

.notification--warning {
  fill: $error;
}

.notification-ring--warning {
  stroke: $error;
}
