@import '../../../../../../styles/_variables.scss';

.baseMenuItem {
  -webkit-text-size-adjust: 100%;
  cursor: pointer;
  display: block;
  outline: none;
  padding: 5px 20px;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    background-color: $grayLight;

    .label {
      color: $textPrimary;
    }
  }

  @media(min-width: $tablet) {
    font-size: 14px;
    line-height: 24px;
  }
}

.label {
  color: $textPrimary;
}
