@import '../../styles/variables';

.slMapNotification {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 18px;
  border-radius: 57px;
  border: none;
  position: absolute;
  top: 128px;
  left: 50%;
  transform: translateX(-50%);
  z-index: zindex(over-map);
  margin: 0;
  height: 18px;
  box-shadow: 0 5px 10px 0 rgba(color('gray', 55), 0.45);
  @media (max-width: $tablet) {
    max-width: 90%;
    height: auto;
  }
}

.slMapNotificationWithMessage {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  padding: 18px 24px;
  @media (max-width: $tablet) {
    white-space: wrap;
    text-align: center;
    padding: 16px 12px;
    border-radius: 12px;
  }
}

.slMapNotificationLoading,
.slMapNotificationWithMessage {
  background-color: color('white');
  padding: 6px 12px;
  .slMapNotificationMessage {
    color: color('black', 40);
  }
}

.slMapNotificationLoading {
  padding: 0px 6px;
  width: auto;
  max-width: 90%;
  @media (min-width: $tablet) {
    height: 32px;
  }
}

.slMapNotificationError {
  background-color: color('bright-red', 30);

  .slMapNotificationMessage {
    color: color('white');
  }
}
