.sl-mobile-forecast-cta {
  margin-top: 16px;
  font-family: $source-sans-pro;
  font-size: 14px;
  text-align: center;
}

.sl-mobile-forecast-cta__text {
  color: color('gray', 90);
}

.sl-mobile-forecast-cta__link {
  color: color('green', 50);
  text-decoration: none;
  font-weight: 600;

  &:hover {
    color: color('green', 70);
  }
}