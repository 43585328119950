@mixin small-paragraph {
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 20px;
  color: color('gray', 70);
}

@mixin paragraph {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .4px;
  color: #6E7273;
}

@mixin sl-hero-paragraph-jumbo {
  color: color('gray', 80);
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1.58px;
  @media(min-width: $tablet) {
    font-size:18px;
    line-height: 28px;
    letter-spacing: 1px;
  }
}

@mixin sl-h4 {
  margin: 0;
  padding-top: 10px;
  font-family: $product-font-linear;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 1px;
  font-weight: 400;
  color: color('gray', 90);
}

@mixin sl-h5 {
  margin: 0;
  padding-top: 10px;
  font-family: $product-font-linear;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: color('gray', 90);
}

@mixin sl-h6 {
  margin: 0;
  font-family: $product-font-linear;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: .88px;
  text-transform: uppercase;
  font-weight: 400;
  color: #96A9B2;
  @media(min-width: $tablet) {
    font-size: 18px;
    letter-spacing: 1px;
  }
}

@mixin action-styles {
  font-family: $product-font-linear;
  text-transform: uppercase;
  color: color('gray', 90);
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before{
      display: block;
      content: " ";
      width: 100%;
      padding-top: ($height / $width) * 100%;
  }

  > .aspect-ratio__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  }
}
