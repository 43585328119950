@import '../_variables.scss';

.sl-spot-list-summary {
  display: none;
  h3 {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 5px;;
    font-family: $source-sans-pro;
    letter-spacing: .5px;
    color: color('gray', 55);
  }
  &__content-summary {
    margin-bottom: 12px;
    padding-bottom: 25px;
    border-bottom: 1px solid color('gray', 30);
    p {
      @include small-paragraph;
      margin-bottom: 5px;
      color: color('gray', 55);
    }
    a {
      @include small-paragraph;
      margin-bottom: 5px;
      text-decoration: none;
      color: color('gray', 55);
      &:hover {
        color: $gray-blue-dark;
      }
    }
  }
  &__beaches {
    margin-top: 15px;
    a {
      @include small-paragraph;
      margin-bottom: 5px;
      text-decoration: none;
      color: color('gray', 55);
      &:hover {
        color: $gray-blue-dark;
      }
    }
  }
  @media (min-width: $tabletLg) {
    margin: 20px;
    display: block;
  }
}
