@import '../../styles/variables';

.pageWrapper {
  min-width: 300px;
  overflow: hidden;

  /*
  TODO: Finalize the padding with Kelly. We may want to make a new "Content Container" for the new pages,
   but this might be easier after we rip out the old spot page components and don't have to support both modes
  */
}
