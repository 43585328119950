// TODO: centralize values in @wavetrak/theme/global.scss
// For the extra small devices (phones)
$small-mobile: 350px;
// For the extra small devices (iphone 6/7/8)
$medium-mobile: 375px;
// For the extra small devices (phones)
$large-mobile: 512px;
// For the extra large devices (phones/tablets in-between)
$x-large-mobile: 635px;
// X-Large devices (x-large desktops, TBD and up)
$x-large-width: 1921px;

// App Breakpoints created within app
$small-mobile-width: 430px;
$mobile-width: 480px;
$small-desktop: 830px;
$desktop-large-width: 1320px;
$desktop-xxl-width: 1600px;

// theme breakpoints - match theme directly
$mobile: 0px;
$tablet: 769px;
$tabletLg: 976px;
$desktopSm: 1024px;
$desktop: 1256px;
$desktopMd: 1440px;
$desktopLg: 1712px;
$desktopXl: 2560px;
