@import '../../../../../../styles/_variables.scss';

.newNotification {
  margin-top: 2px;
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 8px;
  width: 48px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: white !important;
  background-color: $red60;
}
