@import '../../../../../../../../styles/_variables.scss';

.input {
  border-bottom: 1px solid $textPrimary;
  margin-left: 20px;
  position: relative;
  width: calc(100% - 20px);

  input {
    background: transparent;
    border: 0;
    color: $textPrimary;
    font-size: 18px;
    line-height: 1.3em;
    width: 100%;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $textSecondary;
    }
  }

  @media(min-width: $tablet) {
    margin-left: 20px;
    width: calc(100% - 20px);

    input {
      font-size: 28px;
      width: calc(100% - 112px);
    }
  }
}

.icon {
  fill: none;
  height: 12px;
  left: -16px;
  position: absolute;
  stroke: $textPrimary;
  stroke-width: 2px;
  top: 8px;
  width: 12px;

  @media(min-width: $tablet) {
    height: 17px;
    left: -24px;
    top: 12px;
    width: 16px;
  }
}

.link {
  align-items: center;
  color: $textPrimary;
  display: flex;
  font-size: 12px;
  padding: 4px 2px 4px 4px;
  position: absolute;
  right: 0;
  text-decoration: none;
  text-transform: uppercase;
  top: 30px;

  .linkIcon {
    height: 12px;
    padding-left: 2px;
    stroke: $textPrimary;
    stroke-width: 1px;
    width: 12px;
  }

  &:hover,
  &:focus-visible {
    border-radius: 4px;
    color: $brandPrimary;
    outline: 0;

    .linkIcon {
      stroke: $brandPrimary;
    }
  }

  @media(min-width: $tablet) {
    top: 8px;
  }
}
