@import '../../../../../../../../styles/_variables.scss';

.childList {
  height: auto;
  line-height: 24px;
  width: 100%;

  @media(min-width: $tabletLg) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 430px;
    margin-top: 16px;

    div {
      font-size: 14px;
      line-height: 24px;
      margin-right: 16px;
    }
  }
}

.childList--spot {
  height: auto;
  line-height: 24px;
  width: 100%;

  @media(min-width: $tabletLg) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 16px 0;
    max-height: 665px;

    div {
      font-size: 14px;
      line-height: 24px;
      margin-right: 16px;
    }
  }
}

.message {
  margin: 20px 0;

  @media(min-width: $tabletLg) {
    margin: 0;
  }
}
