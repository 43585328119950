@import '../_mixins.scss';

.sl-travel-map {
  position: relative;
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media(min-width: $desktop) {
    height: 600px;
  }
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  &__overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9;
    h3 {
      @include sl-h4;
      text-transform: uppercase;
      text-align: center;
      max-width: 280px;
      margin: 0 auto;
      padding: 0 0 30px;
      @media(min-width: $large-mobile) {
        max-width: 300px
      }
      @media(min-width: $tablet) {
        max-width: 450px;
      }
    }
  }
  &__button {
    margin: 0 auto;
  }
}
