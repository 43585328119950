@import '../_mixins.scss';

.sl-related {
  position: relative;
  max-width: $desktop;
  margin: 90px auto 30px;
  padding: 0;
  text-align: center;
  @media(min-width: $tablet) {
    margin: 90px auto;
    padding: 0 50px;
  }
  h3 {
    @include sl-h4;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    @media(min-width: $tablet) {
      padding-bottom: 75px;
    }
  }
  &__articles {
    height: 730px;
    overflow: hidden;
    @media(min-width: $tablet) {
      height: auto;
      overflow: visible;
    }
    &--open {
      height: auto;
      overflow: visible;
    }
    &__article {
      display: inline-block;
      width: calc(100% - 20px);
      min-height: 345px;
      margin: 0 0 30px;
      vertical-align: top;
      border: 1px solid color('gray', 30);
      text-align: left;
      text-decoration: none;
      &:hover {
        box-shadow: 0px 10px 23px 0px rgba(153, 153, 153, 0.5);
        transition: .5s;
      }
      @media(min-width: $large-mobile) {
        width: 368px;
        margin: 0 15px 30px;
      }
      &__img {
        width: 100%;
        height: 210px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      &__content {
        padding: 20px;
        &__top {
          a {
            @include sl-h6;
            display: inline-block;
            padding-right: 30px;
            font-size: 14px;
            color: color('light-blue', 50);
            text-decoration: none;
            @media(min-width: $tablet) {
              font-size: 14px;
            }
            &:hover {
              color: color('light-blue', 70);
            }
          }
          &__time {
            position: relative;
            display: block;
            left: 18px;
            color: color('gray', 50);
            font-size: 12px;
            &:hover {
              color: color('gray', 50);
            }
            @media(min-width: $small-mobile) {
              display: inline-block;
              left: 0;
            }
            &__clock {
              position: absolute;
              left: -18px;
              top: 2px;
            }
            &__updated {
              display: inline-block;
              max-width: 120px;
              white-space: nowrap;
              overflow-x: hidden;
              text-overflow: ellipsis;
              vertical-align: top;
              @media(min-width: $large-mobile) {
                max-width: 135px;
              }
              span {
                font-weight: 600;
                &:hover {
                  color: color('gray', 50);
                }
              }
              &:hover {
                color: color('gray', 50);
              }
              &:before {
                content: '|';
                margin: 0 3px;
              }
            }
          }
        }
        h4 {
          @include sl-h5;
        }
      }
    }
  }
  &__more {
    display: block;
    @media(min-width: $tablet) {
      display: none;
    }
    &--open {
      display: none;
    }
    .quiver-button--info {
      margin: 30px auto 0;
      width: calc(100% - 20px);
      @media(min-width: $large-mobile) {
        width: 368px;
      }
    }
  }
}
