@import '../../../../../../styles/_variables.scss';

.navigationItem {
  display: inline-block;
  margin: 0 0 0 20px;
  padding: 0px 0px;
  position: relative;

  &:first-child {
    margin-left: 16px;
  }

  &:last-child {
    margin-right: 16px;
  }

  @media (min-width: $tabletLg) {
    display: inline-block;
    margin: 0;
    padding: 0;
    position: relative;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.navigationItem--focus {
  @media (min-width: $tabletLg) {
    &:hover,
    &:focus-visible,
    &:focus-within {
      :global {
        .sl-menu-wrapper {
          display: block;
        }
      }
    }
  }
}


.link {
  color: $textPrimary;
  display: block;
  position: relative;
  text-decoration: none;
  white-space: nowrap;

  sup {
    font-size: xx-small;
    line-height: 0;
    position: relative;
    top: -0.05em;
    vertical-align: super;
  }

  &:hover,
  &:focus-visible {
    outline: none;

    span {
      color: $textPrimary;
    }

    &::after {
      background-color: $brandSecondary;
      content: '';
      display: block;
      height: 4px;
      width: 100%;
      bottom: -6px;
      position: absolute;
    }
  }

  @media (min-width: $tabletLg) {
    vertical-align: bottom;
    padding: 20px;

    &:hover,
    &:focus-visible {
      &::after {
        width: calc(100% - 38px);
        bottom: 2px;
      }
    }
  }
}

.link--isAvatar {
  &:hover,
  &:focus-visible {
    &::after {
      display: none;
    }
  }
}

.label {
  font-size: 14px;

  @media (min-width: $tablet) {
    font-size: 16px;
  }
}
