@import '../../../../styles/_variables.scss';

@mixin conditionColor($color) {
  background-color: $color;
  border: none;
}

.favoritesBar {
  display: none;

  @media (min-width: $tablet) {
    background-color: $white;
    border: 1px solid $grayLight;
    border-left: none;
    border-right: none;
    display: block;
    height: $favorites-bar-height;
    margin: 0;
    overflow: hidden;
    width: 100%;
  }
}

.link {
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 0 8px;
}

.linkAnchor {
  align-items: center;
  border-radius: 10px 20px 20px 10px;
  color: $textPrimary;
  display: flex;
  justify-content: center;
  outline: none;
  text-decoration: none;
  transition: all 400ms ease 0s;

  &:hover,
  &:focus-visible {
    background-color: $grayLight;

    .linkLabel {
      color: $textPrimary;
    }

    .linkIcon {
      transform: scale(110%);
    }
  }
}

.linkIcon {
  transition: all 200ms ease 0s;
}

.linkLabel {
  font-size: 10px;
  height: 12px;
  margin-left: 8px;
  text-transform: uppercase;
  width: 72px;
}

.content {
  color: $white;
  height: 100%;
  width: 100%;
}

.contentFavorites {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.contentFavoritesTitle {
  align-items: center;
  border-right: 1px solid $grayLight;
  color: $textPrimary;
  display: flex;
  flex: 0 0 auto;
  font-size: 12px;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: $favorites-bar-height;
  margin: 0;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 400ms ease 0s;
  width: 110px;

  &:hover,
  &:focus-visible {
    background-color: $grayLight;
  }
}

.scrollWrapper {
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: $favorites-bar-height;
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  white-space: nowrap;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.contentFavoritesList {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 0;
  white-space: nowrap;
}

.contentFavoritesItem {
  display: inline-block;
}

.contentFavoritesArrows {
  flex: 0 0 auto;
}

.contentFavoritesArrow {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: $favorites-bar-height;
  justify-content: center;
  stroke: $textPrimary;
  transition: all 400ms ease 0s;
  width: 29px;

  &:hover,
  &:focus-visible {
    background-color: $grayLight;
  }

  svg {
    width: 8px;
  }
}
