@import '../../../../../../../../styles/_variables.scss';

.header {
  align-items: center;
  border-bottom: 1px solid $gray60;
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  padding: 10px 0;
  width: 100%;

  a {
    color: $brandPrimary;
    display: none;
    font-size: 14px;
    margin-left: 24px;
    text-decoration: none;
  }

  @media(min-width: $tabletLg) {
    border-top: 1px solid $gray60;
    justify-content: flex-start;
    margin-top: 8px;
    padding: 5px 0;

    a {
      display: inline;

      &:hover,
      &:focus-visible {
       text-decoration: underline;
      }
    }
  }
}

.button {
  @media(min-width: $tabletLg) {
    display: none;
  }
}

.headerTitle {
  color: $textPrimary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
