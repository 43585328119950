/* stylelint-disable selector-pseudo-class-no-unknown */
@import '../../styles/variables';

$tablet-height: calc($site-header-height-mobile + $site-header-favorites-bar-height);

.slMapPageV2 {
  display: flex;
  flex-flow: row-reverse nowrap;
  position: relative;

  height: calc(100vh - $site-header-height-mobile);
  @supports (height: calc(100svh - $site-header-height-mobile)) {
    height: calc(100svh - $site-header-height-mobile);
  }

  .slMapV2RightRail {
    position: relative;
    width: 100%;
    z-index: 2;

    height: calc(100vh - $site-header-height-mobile);
    @supports (height: calc(100svh - $site-header-height-mobile)) {
      height: calc(100svh - $site-header-height-mobile);
    }
  }
}

@media (min-width: $tablet) {
  .slMapPageV2 {
    height: calc(100vh - $tablet-height);
    @supports (height: calc(100svh - $tablet-height)) {
      height: calc(100svh - $tablet-height);
    }

    .slMapV2RightRail {
      height: calc(100vh - $tablet-height);
      @supports (height: calc(100svh - $tablet-height)) {
        height: calc(100svh - $tablet-height);
      }
    }
  }
}

@media (min-width: $tabletLg) {
  .slMapPageV2 {
    .slMapV2RightRail {
      display: block;
      float: right;
      width: calc(100% - 340px);
    }
  }
}

@media (min-width: $tabletLg) {
  .slMapPageV2 {
    height: calc(100vh - $site-header-height-desktop);
    @supports (height: calc(100svh - $site-header-height-desktop)) {
      height: calc(100svh - $site-header-height-desktop);
    }

    .slMapV2RightRail {
      height: calc(100vh - $site-header-height-desktop);
      @supports (height: calc(100svh - $site-header-height-desktop)) {
        height: calc(100svh - $site-header-height-desktop);
      }
    }
  }
}

@media (min-width: $desktop) {
  .slMapPageV2 {
    .slMapV2RightRail {
      width: calc(100% - 660px);
    }
  }
}

.slMapPageV2 .slMapV2LeftRail {
  bottom: 0;
  height: unset;
  margin: 0;
  position: absolute;
  width: 100%;
  z-index: 3;

  @media (min-width: $tabletLg) {
    margin-bottom: unset;
    position: unset;
    background-color: $product-extra-light;
    width: 340px;
    height: 100%;
  }

  @media (min-width: $desktop) {
    width: 660px;
  }
}

.slMapPageV2Loading,
.slMapPageV2Error {
  :global {
    .leaflet-map-pane {
      opacity: 0.4;
    }
  }
}

// Global overrides
.slMapPageV2 {
  :global {
    .sl-quiver-map {
      // ensure the map has a lower z-index than the nav bar items
      z-index: zindex(map);
      background: color('blue-gray', 10);
    }

    .leaflet-pane {
      z-index: zindex(map-tile-layer);

      .leaflet-tile-pane {
        z-index: zindex(map-tile-layer);
      }
    }

    .leaflet-top,
    .leaflet-bottom {
      z-index: zindex(map-control);

      .leaflet-bar {
        border: none;
      }
    }

    .leaflet-control-zoom {
      margin: 20px 20px 0 0;

      @media (min-width: $tablet) {
        display: block;
        margin: 30px 30px 0 0;
      }
    }

    .leaflet-map-pane {
      transition: opacity 0.15s ease-in-out;
    }

    .leaflet-bar {
      background-color: transparent;
      border: none;
      border-radius: 16px;
    }

    .leaflet-touch .leaflet-bar a:first-child {
      background-color: $product-secondary;
      border: none;
      border-bottom: 1px solid $product-body;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      color: $product-base;
      width: 32px;
      height: 32px;

      &:hover,
      &:active,
      &:focus-visible {
        background-color: $product-body;

        span {
          color: $product-base;
        }
      }
    }

    .leaflet-touch .leaflet-bar a:last-child {
      background-color: $product-secondary;
      border: none;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      color: $product-base;
      width: 32px;
      height: 32px;

      &:hover,
      &:active,
      &:focus-visible {
        background-color: $product-body;

        span {
          color: $product-base;
        }
      }
    }
  }
}
