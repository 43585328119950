@use 'sass:math';

$table-border-spacing: 2px;
$table-border-color: #E6E6E6;

.sl-forecast-table {
  position: relative;
  min-height: 400px;
  padding: 0 0 64px;

  .sl-forecast-table-cta {
    height: 546px;
    padding: 32px 8px 0;
    background: $paywall-background;
  }

  .sl-forecast-table-cta-td {
    padding: 0px;
  }

  .sl-chevron-box {
    vertical-align: bottom;
    margin-right: 8px;
  }
}

.sl-forecast-table__container {
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
  margin-bottom: 16px;
  -webkit-overflow-scrolling: touch;
}

.sl-forecast-table__subheader {
  display: block;
  padding: 0;
}

.sl-forecast-table__table {
  min-width: 100%;
  margin-top: 16px;
  padding: 0;
  border-spacing: 0;
  color: color('gray', 90);
  overflow: scroll;
}

.sl-forecast-table__table-wrapper {
  width: fit-content;
  display: inline-block;
  min-width: 100%;
  padding: 0;
  box-sizing: border-box;
}

.sl-forecast-table .sl-forecast-table__table,
th,
td {
  border: 1px solid color('gray', 30);
  border-collapse: collapse;
}

.sl-chevron-box {
  background-color: white;
}

.sl-chevron-box .quiver-chevron {
  background-color: white;
  stroke: color('gray', 90);
}

.sl-forecast-table__day {
  &--blurred {
    filter: blur(6px);
  }
}

.sl-forecast-table__timestep {
  outline: none;

  &:hover {
    .sl-chevron-box {
      background-color: white;
    }
  }

  &--day {
    cursor: pointer;

    .sl-forecast-table__cell {
      background-color: white;
    }
  }

  &--time {
    th {
      padding-left: 32px;
      min-width: 70px;
      text-transform: lowercase;
      font-weight: normal;
    }

    .sl-forecast-table__cell {
      background-color: white;
    }
  }

  &--expanded {
    .sl-chevron-box {
      background-color: white;
    }

    .sl-forecast-table__cell {
      background-color: white;
    }
  }
}

.sl-forecast-table__cell {
  height: 40px;
  padding: 0 8px;
  text-align: left;
  font-size: 14px;
  font-family: $source-sans-pro;
  font-weight: 600;
  white-space: nowrap;

  &--header {
    background: none;
    font-family: $product-font-linear;
    font-size: 16px;
    font-weight: normal;
    padding: 0 8px;
    text-align: left;
    background-color: color('white');
    white-space: nowrap;
  }

  &--optimal {
    background-color: $optimal-score-color !important;
  }

  &--good {
    background-color: $good-score-color !important;
  }

  @each $height, $colors in $swell-height-colors-ft {
    &--swell-#{$height}-ft {
      background-color: map-get($colors, background-color) !important;
      color: map-get($colors, color);
    }
  }

  @each $height, $colors in $swell-height-colors-m {
    &--swell-#{$height}-m {
      background-color: map-get($colors, background-color) !important;
      color: map-get($colors, color);
    }
  }
}

.sl-forecast-table__units {
  text-transform: lowercase;
}

.sl-forecast-table__direction {
  font-weight: normal;
  white-space: nowrap;
  display: block;
}

.sl-forecast-table__custom-wind {
  display: flex;
  align-items: center;
}

.sl-forecast-table__custom-wind .quiver-wind-arrow {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
}

.sl-forecast-table__custom-wind .sl-forecast-table__wind-reading-text {
  text-align: left;
}

// Nested `&` requires special handling in mixins:
// https://github.com/webpack-contrib/sass-loader/issues/351
@mixin cell-widths($surf-width, $swell-wind-width, $custom-metric-width) {
  .sl-forecast-table__cell {
    @at-root #{&}--surf {
      width: $surf-width;
    }

    @at-root #{&}--swell {
      width: $swell-wind-width;
    }

    @at-root #{&}--wind {
      width: $swell-wind-width;
    }

    @at-root #{&}--significant-height {
      width: $swell-wind-width + 25px;
    }

    @at-root #{&}--custom-metric {
      width: $custom-metric-width;
    }
  }
}

// To be able to divide the extra space evenly,
// ($base-swell-wind-width + $table-border-spacing) and ($base-surf-width + $table-border-spacing)
// should be multiples of 4
@mixin all-cell-widths($base-surf-width, $base-swell-wind-width, $base-custom-metric-width) {
  $extra-width-3-swell: ($base-swell-wind-width + $table-border-spacing) * 3;
  $extra-width-surf-and-wind: $base-surf-width + $base-swell-wind-width +
    ($table-border-spacing * 2);

  @include cell-widths($base-surf-width, $base-swell-wind-width, $base-custom-metric-width);

  .sl-forecast-table--hide-surf-and-wind {
    @include cell-widths(
      default,
      $base-swell-wind-width + math.div($extra-width-surf-and-wind, 6),
      $base-custom-metric-width
    );
  }
}

@media (min-width: $tablet) {
  .sl-forecast-table__subheader {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid color('blue-gray', 20);
    border-bottom: 1px solid color('blue-gray', 20);
  }

  .sl-forecast-table__cell {
    padding-left: 12px;
    font-size: 16px;
  }
}

@media (min-width: $tabletLg) {
  @include all-cell-widths(70px, 94px, 140px);

  .sl-forecast-table__container {
    margin-bottom: 0;
  }

  .sl-forecast-table__table-wrapper {
    padding: 0;
  }

  .sl-forecast-table__subheader {
    border-top: none;
  }

  .sl-forecast-table__cell {
    .sticky-inner-wrapper {
      padding: 8px 12px;
      background-color: color('white');
    }
  }

  .sl-forecast-table__cell--header {
    min-width: 125px;
    padding: 0 !important;

    .sticky-outer-wrapper {
      min-width: 125px;
    }

    &.sl-forecast-table__cell--direction {
      min-width: 140px;

      .sticky-outer-wrapper {
        min-width: 140px;
      }
    }

    &.sl-forecast-table__cell--significant-height {
      min-width: 170px;

      .sticky-outer-wrapper {
        min-width: 170px;
      }
    }
  }

  .sl-forecast-table__table {
    width: 100%;
  }

  .sl-forecast-table__timestep--time {
    th {
      padding-left: 36px;
    }
  }
}

@media (min-width: $desktop) {
  @include all-cell-widths(82px, 126px, 160px);

  .sl-forecast-table__timestep--time {
    th {
      padding-left: 40px;
    }
  }

  .sl-forecast-table__cell {
    padding-left: 16px;
  }
}

@media (min-width: $desktopLg) {
  @include all-cell-widths(86px, 170px, 250px);

  .sl-forecast-table__direction {
    display: inline;
  }
}

.sl-forecast-table__header__text {
  padding: 0 8px;
}

.sl-forecast-table__header__cell-fill {
  background: color('white');
  border-bottom: 1px solid $table-border-color;
  border-right: 1px solid $table-border-color;
  bottom: -2px;
  left: 1px;
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: -1;

  &.swell-header {
    left: 0px;
  }
}
