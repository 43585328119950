@import '@surfline/quiver-assets/productcdn';

@font-face {
  font-family: 'Linear Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-regular.woff2') format('woff2'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-regular.woff') format('woff'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-regular.eot') format('embedded-opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
  font-family: 'Linear Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-italic.woff2') format('woff2'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-italic.woff') format('woff'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-italic.eot') format('embedded-opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Linear Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-semi-bold.woff2') format('woff2'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-semi-bold.woff') format('woff'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-semi-bold.eot')
      format('embedded-opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
  font-family: 'Linear Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-bold-italic.woff2') format('woff2'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-bold-italic.woff') format('woff'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-bold-italic.eot')
      format('embedded-opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Linear Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-bold.woff2') format('woff2'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-bold.woff') format('woff'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-bold.eot') format('embedded-opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
  font-family: 'Linear Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-bold-italic.woff2') format('woff2'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-bold-italic.woff') format('woff'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-bold-italic.eot')
      format('embedded-opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Linear Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-black.woff2') format('woff2'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-black.woff') format('woff'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-black.eot') format('embedded-opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
  font-family: 'Linear Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-black-italic.woff2') format('woff2'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-black-italic.woff') format('woff'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-black-italic.eot')
      format('embedded-opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Linear Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-heavy.woff2') format('woff2'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-heavy.woff') format('woff'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-heavy.eot') format('embedded-opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
  font-family: 'Linear Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-heavy-italic.woff2') format('woff2'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-heavy-italic.woff') format('woff'),
    url('#{$sl-product-cdn}/fonts/linear-sans/linear-sans-heavy-italic.eot')
      format('embedded-opentype');
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
