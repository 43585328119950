.sl-chevron-box {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: color('blue-gray', 40);

  .quiver-chevron {
    display: flex;
    margin: 2px;
    width: 12px;
    height: 12px;
    stroke: color('white');
  }
}
