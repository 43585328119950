@import '../../styles/variables';

.container {
  width: 100%;
  transition: margin-top 50ms ease-in-out;
}

.container > .swellEventCardWrapper:first-child {
  padding-top: 20px;
}
