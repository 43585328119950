@import '../../../../../../styles/_variables.scss';

.userName,
.userEmail,
.premiumStatus,
.notification {
  line-height: 21px;
  padding: 0 20px;
}

.userName,
.userEmail {
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 24px;
}

@media (min-width: $tablet) {
  .userName,
  .userEmail {
    font-size: 14px;
    letter-spacing: 0.2px;
  }
}

.userName {
  color: $textPrimary;
  margin-top: 8px;

  span {
    text-transform: capitalize;
  }
}

.userEmail {
  color: $textSecondary;
}

.premiumStatus {
  color: $success;
  padding-bottom: 5px;
  padding-top: 5px;
}

.premiumStatus,
.cta {
  color: $success;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.notification {
  color: $success;
  display: block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.78px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-decoration: none;

  &:hover &:focus-visible {
    color: $success;
  }
}

.notificationWarning {
  color: $warning;

  &:hover,
  &:focus-visible {
    color: $warning;
  }
}

.featureRelease,
.premiumPerks {
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.userDetailsSectionPremiumPlus {
  background-color: $product-secondary;
  padding-top: 2px;
  padding-bottom: 6px;
  .userName {
    color: $product-extra-light;
  }
  .userEmail {
    color: $product-light;
  }
  .premiumPlusStatus {
    color: $brand-expressive-bronzing;
  }
}
