.sl-spot-list-message-header {
  display: none;
  background-color: color('white');
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
}

@media (max-width: $tabletLg) {
  .sl-spot-list-message-header--no-spots {
    display: inline-block;

    .sl-spot-list-message-header__text {
      position: absolute;
      top: 50%;
      transform: translate(20px, -50%);
    }
  }
}

.sl-spot-list-message-header__text {
  @include action-styles;
  float: left;
  letter-spacing: 0.3px;
  font-size: 12px;
  height: 20px;
  padding-top: 5px;
}

@media (min-width: $tabletLg) {
  .sl-spot-list-message-header {
    display: inline-block;
    width: 96.8%;
    height: 32px;
    padding: 14px 20px;
  }

  .sl-spot-list-message-header__text {
    display: block;
    height: 22px;
    padding-top: 8px;
    font-family: $source-sans-pro;
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 14px;
  }
}

@media (max-width: $desktop) {
  .sl-spot-list-message-header__text--hidden {
    display: none;
  }
}
