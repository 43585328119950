@import '../../styles/_variables.scss';

.footer {
  background-color: $background;
  display: flex;
  min-width: 320px;
  padding: 40px 0;
  width: 100%;

  @media (min-width: $tabletLg) {
    padding: 60px 0 80px;
  }
}

.footerHidden {
  display: none;
}

.footerError {
  min-height: 200px;
}

.container {
  box-sizing: border-box;
  display: block;
  position: relative;

  @media (min-width: $tablet) {
    display: flex;
    flex-wrap: wrap;
  }
}

.linkGroup {
  list-style: none;
  margin: 0 0 60px 0;
  order: 1;
  padding: 0;
  text-align: center;

  li {
    margin-bottom: 0.33em;
  }

  h4 {
    margin-bottom: 1em;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;

    span {
      color: $textPrimary;
    }

    &:hover,
    &:focus-visible {
      span {
        color: $blue40;
      }
    }
  }

  @media (min-width: $tablet) {
    margin-right: 60px;
    text-align: left;
  }

  @media (min-width: $desktop) {
    margin-bottom: 0;
    order: 2;
  }
}

.copyright {
  color: $textPrimary;
  display: block;
  list-style: none;
  margin: 0;
  order: 3;
  padding: 0;
  text-align: center;
  width: 100%;

  li {
    margin-bottom: 0.33em;

    &:first-child {
      margin-bottom: 1em;
    }
  }

  a {
    text-decoration: none;

    span {
      color: $textPrimary;
    }

    &:hover,
    &:focus-visible {
      span {
        color: $blue40;
      }
    }
  }

  @media (min-width: $tablet) {
    text-align: left;
  }

  @media (min-width: $desktop) {
    display: inline-block;
    margin-right: 60px;
    order: 1;
    width: auto;
  }
}

.copyrightLogo {
  display: none;

  @media (min-width: $tablet) {
    display: block;
  }
}

.icons {
  display: inherit;
  margin-bottom: 60px;
  order: 2;
  text-align: center;

  @media (min-width: $tablet) {
    display: block;
    height: 0;
    margin-left: auto;
  }

  @media (min-width: $tabletLg) {
    height: auto;
    margin-left: auto;
    order: 3;
  }
}

.iconsLogo {
  display: inline-block;

  @media (min-width: $tablet) {
    display: none;
  }
}

.iconsMSW {
  margin-top: 40px;
  text-align: center;

  @media (min-width: $tablet) {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}

.msw {
  display: inline-block;
  width: 67px;
  height: 25px;
}

.socialLogos {
  display: block;
  list-style: none;
  margin: 60px 0 0;
  padding: 0;

  li {
    display: inline-block;

    &:first-child {
      a {
        margin-left: 0;
      }
    }

    &:last-child {
      a {
        margin-right: 0;
      }
    }
  }

  a {
    background: $white;
    border-radius: 4px;
    display: inline-block;
    height: 36px;
    margin: 0 10px;
    outline: none;
    text-align: center;
    width: 36px;

    svg {
      fill: $brandSecondary;
      height: 18px;
      margin-top: 9px;
      width: 18px;
    }

    &:hover,
    &:focus-visible {
      svg {
        fill: $blue40;
      }
    }
  }

  @media (min-width: $tablet) {
    margin-top: 0;
  }
}

// used on maps pages like - /surf-reports-forecasts-cams-map
.containerSmall {
  .socialLogos {
    a {
      height: 24px;
      margin: 0 10px;
      width: 24px;

      svg {
        height: 12px;
        margin-top: 6px;
        width: 12px;
      }
    }
  }

  .iconsMSW {
    margin-top: 40px;
    text-align: center;
  }

  @media (min-width: $tablet) {
    display: block;

    .linkGroup {
      margin-bottom: 20px;
      margin-right: 0;
      text-align: center;
    }

    .copyright {
      text-align: center;
    }

    .copyrightLogo {
      display: none;
    }

    .icons {
      height: auto;
      margin-bottom: 20px;
    }

    .iconsLogo {
      display: inline-block;
    }

    .socialLogos {
      margin-top: 20px;
    }

    .iconsMSW {
      position: inherit;
      right: unset;
      bottom: unset;
    }
  }

  @media (min-width: $tabletLg) {
    .copyright {
      display: block;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }

  @media (min-width: $desktop) {
    display: flex;

    .linkGroup {
      margin-right: 40px;
      text-align: left;
    }

    .copyright {
      display: block;
      order: 3;
      text-align: left;
      width: 100%;
    }

    .copyrightLogo {
      display: block;
    }

    .icons {
      height: 0;
      margin-left: auto;
    }

    .iconsLogo {
      display: none;
    }

    .socialLogos {
      margin-top: 0;
    }

    .iconsMSW {
      position: absolute;
      right: 10px;
      bottom: 25px;
    }
  }
}
