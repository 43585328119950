@import '../../styles/variables';


.conditionsBlock {
  width: 4px;
  height: 20px;
  border-radius: 4px;
  margin-right: 8px;
}

.endIcon {
  margin-top: 3px;
}

.menuWrap {
  max-height: 1000px;
  overflow: auto;
  padding: 15px;
}

.nearbySpotLink {
  text-decoration: none;
  display: 'flex';
  margin: 0;
  padding: 5px 0;
  min-height: 0;

  &:hover, &:focus, &:active {
    background: white;

    * {
        color: $product-secondary;
        text-decoration: none;
    }

    p {
      text-decoration: underline;
    }
  }
}

.nearbySpotsLinkName {
  max-width: 232px;
  margin-right: 15px;
}

.nearbySpotsButton {
  margin: 0 15px;
  position: relative;
  background: white;

  &.isOpen {
   z-index: zindex(nearby-spots-menu) + 1; 
  }

  &:hover, &:focus, &:active {
    background: white;
  }
}

.nearbySpotsItem {
  display: 'flex';
  margin: 0;
  padding: 5px 0;
  min-height: 0;
}

.nearbySpotsBackdrop {
  z-index: zindex(nearby-spots-menu);
}

.popperWrapper {
  z-index: zindex(nearby-spots-menu) + 1;
  border-radius: 8px;
}

.paper {
  border-radius: 8px;
  margin-top: 15px;
}

.popoverTriangle {
  display: block;
  transition: opacity 2s 1;
  position: absolute;
  top: 60px;
  right: 47%;
  width: 10px;
  height: 10px;
  background: white;
  transform: translateY(-50%) rotate(45deg);
}

.iconWrapper {
  margin-left: 4px;
}

.insightsIconWrapper {
  margin-left: 4px;
  margin-top: 2px;
  svg {
    width: 14px;
    height: 14px;
  }
}
