@import '../../../../../../styles/_variables.scss';

.camsReportsMenu {
  cursor: default;
  display: block;
  padding: 0px 8px 8px 8px;
  background-color: $white;

  :global {
    .sl-taxonomy-navigator {
      height: 100%;
      padding: 0 16px 8px;
    }
  }

  @media(min-width: $tabletLg) {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 10px 10px rgba(0,0,0,0.15), 0 5px 5px rgba(0,0,0,0.12);
    cursor: default;
    display: flex;
    flex-direction: row;
    height: $site-header-taxonomy-navigator-height;
    left: -48px;
    position: absolute;
    top: calc(100% - 2px);
    z-index: 100;

    :global {
      .sl-taxonomy-navigator {
        margin-left: 8px;
        width: $site-header-taxonomy-navigator-width;
      }
    }

    @media(min-width: $desktopSm) {
      left: -133px;
    }

    /* IE 11 */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .camsReportsMenu {
        width: 958px;
      }
    }
  }
}



