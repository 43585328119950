:root {
  --sl-section-container-gutter: 20px;

  @media (min-width: $large-mobile) {
    --sl-section-container-gutter: 30px;
  }

  @media (min-width: $tablet) {
    --sl-section-container-gutter: 40px;
  }

  @media (min-width: $tabletLg) {
    --sl-section-container-gutter: 50px;
  }

  @media (min-width: $desktop) {
    --sl-section-container-gutter: 60px;
  }
}

.sl-section-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sl-section-container-gutter);
  padding-right: var(--sl-section-container-gutter);
  width: 100%;

  .sl-section-full-bleed {
    margin-left: calc(var(--sl-section-container-gutter) * -1);
    width: calc(100% + (var(--sl-section-container-gutter) * 2));
  }

  @media (min-width: $tabletLg) {
    .sl-section-full-bleed {
      margin-left: 0;
      width: 100%;
    }
  }

  @media (min-width: $desktop) {
    max-width: calc($desktop-xxl-width + (var(--sl-section-container-gutter) * 2));
  }
}
