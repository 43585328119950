@import '../../styles/variables';

.pageRailLeft {
  width: 100%;

  @media (min-width: $tabletLg) {
    margin-right: 16px;
    width: calc(100% - #{$right-rail-width} - 16px);
  }
}

.pageRailRight {
  width: 100%;

  @media (min-width: $tabletLg) {
    width: $right-rail-width;
  }
}
