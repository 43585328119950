@import '../../../../../../../../styles/_variables.scss';

.breadcrumb {
  display: none;
  line-height: 32px;
  width: 100%;

  @media(min-width: $tabletLg) {
    display: flex;
    height: 32px;
    padding-top: 6px;
  }
}
