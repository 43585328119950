@import './variables';
@import './fonts/font-imports';
@import './conditions';

// base files
@import './normalize.css';
@import './base-quiver';
@import '~@wavetrak/theme/global.css';

// Global Containers
@import './containers/routes/PremiumAnalysis.scss';
@import './containers/routes/SpotPage.scss';
@import './containers/routes/SpotReportPage.scss';

// Global Components
@import './components/ChangeUnits.scss';
@import './components/ChevronBox.scss';
@import './components/ChildGrid.scss';
@import './components/Container.scss';
@import './components/FeedArticle.scss';
@import './components/ForecastTable.scss';
@import './components/ForecastTableControls.scss';
@import './components/MapTiler.scss';
@import './components/MobileForecastCTA.scss';
@import './components/MultiCamPaywall.scss';
@import './components/PageLoading.scss';
@import './components/PremiumAnalysis.scss';
@import './components/RelatedArticles.scss';
@import './components/ScrollControl.scss';
@import './components/SpotList.scss';
@import './components/SpotListMessageHeader.scss';
@import './components/SpotListSiblings.scss';
@import './components/SpotListSummary.scss';
@import './components/SpotTravelArticle.scss';
@import './components/StickyAdUnit.scss';
@import './components/SwellHeightLegend.scss';
@import './components/TravelMap.scss';
@import './components/TravelSummary.scss';
@import './components/VideoJsSkins.scss';
@import './components/ViewForecastTravel.scss';

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.4px;
  min-width: 350px;
}

body.sl-map-page {
  overflow: hidden;
}

body.sl-map-v2 {
  #backplane-footer {
    display: none;
  }
}

button {
  padding: 0;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
}

iframe#launcher {
  // This is used to move the zendesk help widget to the right side of the screen (asked for by
  // product)
  left: unset !important;
  right: -10px;
}

@media (max-width: $tabletLg) {
  .sl-hide-ze-widget {
    .zEWidget-ticketSubmissionForm,
    .zEWidget-automaticAnswers,
    .zEWidget-launcher,
    .zEWidget-nps {
      display: none;
    }
  }

  .sl-hide-ze-widget iframe#launcher {
    display: none;
  }
}

.sl-button-link {
  text-decoration: none;
  outline: none;

  .quiver-button {
    color: color('white');

    &:hover {
      color: color('white');
    }
  }
}

.sl-left-rail {
  position: relative;
  width: 100%;
  height: 118px;

  @media (min-width: $tabletLg) {
    width: 340px;
    height: calc(100% - 96px);
  }

  @media (min-width: $desktop) {
    width: 660px;
  }

  @media (min-width: $x-large-width) {
    width: 980px;
  }
}

.sl-right-rail {
  position: relative;
  width: 100%;

  @media (min-width: $tabletLg) {
    display: block;
    width: calc(100% - 340px);
    float: right;
    height: calc(100vh - 96px); // Height of header with favorites bar
  }

  @media (min-width: $desktop) {
    width: calc(100% - 660px);
  }

  @media (min-width: $x-large-width) {
    width: calc(100% - 980px);
  }
}

// Full height for native view
$map-page-native-height: 100vh;

.quiver-page-container__content--native {
  .sl-map-page-v2 {
    height: $map-page-native-height;
  }
}

.jw-display-icon-container.jw-display-icon-rewind.jw-reset {
  display: none !important;
}

#sl-header-ad {
  display: none;

  p {
    color: $product-body-secondary;
  }

  &.active {
    align-items: center;
    background-color: $product-base;
    display: flex;
    justify-content: center;
    min-height: 80px;
    position: relative;
    width: 100%;
    z-index: 1;

    &::before {
      color: $product-body-secondary;
      content: 'Loading Advertisment...';
      font-size: 12px;
      position: absolute;
    }

    @media (min-width: $small-desktop) {
      min-height: 250px;
    }
  }

  &.passive {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.quiver-google-dfp {
  position: relative;
  z-index: 2;
}
