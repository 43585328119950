@import '../../../../../../../../styles/_variables.scss';

.breadcrumbNode {
  align-items: center;
  height: 30px;
  padding: 0;
  width: auto;

  &:last-child {
    .chevron {
      display: none;
    }
  }
}

.chevron {
  height: auto;
  margin: 0 16px;
  stroke: $gray40;
  width: 5.2px;
}

.earth {
  position: relative;
  top: 4px;
}

.path {
  fill: $textPrimary;
}

.button {
  background: none;
  border: none;
  color: $textPrimary;
  cursor: pointer;
  font-size: 14px;
  line-height: normal;
  outline: none;
  padding: 0;

  &:hover,
  &:focus-visible {
    color: $brandPrimary;

    .earth {
      path {
        fill: $brandPrimary;
      }
    }
  }
}

.placeholder {
  color: $gray60;
  font-size: 14px;
}
