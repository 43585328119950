@import '../_variables.scss';

@mixin injectResponsiveStyles(
  $caption-width,
  $caption-margin,
  $paragraph-width
) {
  .sl-travel-summary {
    margin: 0 10px;
    margin-top: 70px;
    p, ul, h1, h2, h3, h4, h5, h6 {
      width: $paragraph-width;
      margin: 0 auto;
      margin-bottom: 35px;
      a {
        text-decoration: none;
      }
    }
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 5px;
    }
    .wp-caption {
      margin: $caption-margin;
      text-align: center;
      width: $caption-width !important;
      p {
        color: color('gray', 50);
      }
    }
  .video-wrap-container {
      margin: $caption-margin;
      width: $caption-width !important;
      p {
        position: relative;
        color: color('gray', 50);
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

@media only screen and (min-width : 0px) {
  @include injectResponsiveStyles(
    $caption-width: 100%,
    $caption-margin: 0 auto,
    $paragraph-width: 100%
  )
}

@media only screen and (min-width : $tabletLg) {
  @include injectResponsiveStyles(
    $caption-width: 90%,
    $caption-margin: 30px auto,
    $paragraph-width: 640px
  )
}

@media only screen and (min-width : $desktop) {
  @include injectResponsiveStyles(
    $caption-width: 960px,
    $caption-margin: 60px auto,
    $paragraph-width: 640px
  )
}
