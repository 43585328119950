@import '../../styles/variables';

.contentCard {
  position: relative;
}

.contentCard--isLink {
  cursor: pointer;
}

.alert {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.action {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: -4px;
}

.anchor {
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}
