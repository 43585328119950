@import '../../../../../../styles/_variables.scss';

@keyframes slScaleDown {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}

.siteSearch {
  background: rgba(255, 255, 255, .975);
  bottom: 0;
  left: 0;
  margin: 0 auto;
  outline: none;
  overflow: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.siteSearch--mobile {
  -webkit-overflow-scrolling: touch;
  animation: slScaleDown .35s;
  background: $white;
}

.searchTop {
  align-items: center;
  background: $background;
  display: flex;
  padding: 32px 0;

  @media(min-width: $tablet) {
    padding: 40px 0;
  }

  @media(min-width: $tabletLg) {
    padding: 40px 0;
  }
}

.searchClose {
  align-items: center;
  color: $white;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 4px;
  top: 4px;

  svg {
    height: 24px;
    width: 24px;
  }

  &:hover,
  &:active,
  &:focus-visible {
    outline: 0;

    svg {
      circle {
        fill: $brandPrimary;
      }
      path {
        fill: $white;
      }
    }
  }

  @media(min-width: $tablet) {
    right: 8px;
    top: 20px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.results {
  box-sizing: border-box;
  padding-bottom: 20px;
  padding-top: 20px;

  @media(min-width: $tablet) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto;
    max-height: 1000px;
    padding-bottom: 40px;
    padding-top: 40px;
  }

  @media(min-width: $tabletLg) {
    height: 100%;
  }
}

.resultsPlaceholder {
  color: $grayLight;
  display: block;
  margin: 0 auto;
  padding: 0 16px;
  text-align: center;

  @media(min-width: $tabletLg) {
    padding: 0 36px 0 16px;

    p {
      font-size: 18px;
    }
  }
}

.loading {
  margin: 20px auto;
}


