@import '../../mixins';

.sl-spot-report-page {
  background-color: color('light-blue', 5);
  padding: 8px 0 32px 0;

  .sl-spot-camera {
    margin: 16px 0;
    padding: 0;
    width: 100%;
  }

  .sl-nearby-buoys,
  .quiver-wetsuit-recommender,
  .sl-ideal-conditions {
    @include module-shadow;
  }
}

.sl-spot-report-page__charts-v2 {
  width: 100%;
  background-color: color('white');
  @include module-shadow;

  .sl-change-units {
    margin-top: -1px;
  }

  .sl-change-units--mobile {
    margin-top: -8px;
    padding-bottom: 16px;
  }
}

.sl-spot-report-page__charts-v2 {
  background-color: unset;
  box-shadow: unset;
}

.sl-spot-report-page__ad {
  display: flex;
  justify-content: center;
}

.sl-spot-report-page__ad--position2-desktop,
.sl-spot-report-page__ad--right1 {
  margin: 16px 0;
  padding: 10px;
  background-color: color('white');
  @include module-shadow;
}

.sl-spot-report-page__ad--position2-mobile {
  min-height: 50px;
}

.sl-spot-report-page__ad--position2-desktop {
  display: none;
  min-height: 250px; // because the companion ad doesn't render immediately.
}

.sl-spot-report-page__ad--bottom {
  display: none;
}

.sl-spot-report-page__sunlight {
  margin: 16px 0;
  background-color: color('gray', 30);
  height: 250px;
}

@media (min-width: $tabletLg) {
  .sl-spot-report-page__ad--position2-mobile {
    display: none;
  }

  .sl-spot-report-page__ad--position2-desktop {
    display: flex;
  }

  .sl-spot-report-page__ad--bottom {
    display: flex;
    width: 100%;
  }
}
