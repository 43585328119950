@import '../../styles/variables';

.box {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: $product-secondary;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  height: 68px;
  @media (min-width: $tablet) {
    height: 56px;
  }
}

.clickableArea {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  width: calc(100% - 40px);
  justify-content: center;
  svg {
    margin-right: 8px;
  }
}

.text {
  color: $product-extra-light;
  font-size: 14px;
  padding-right: 40px;
}

.premiumPlusText {
  color: $brand-expressive-bronzing;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.closeButton {
  position: absolute;
  right: 12px;
  color: $product-extra-light;
  @media (min-width: $tablet) {
    top: 6px;
    right: 12px;
  }
}
