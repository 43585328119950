@import '../mixins';

.sl-spot-travel-article {
  display: flex;
  padding: 24px;
  margin-bottom: 16px;
  background-color: color('white');
  @include module-shadow;
}

  .sl-spot-travel-article__thumbnail {
    margin: 0 24px 0 0;
    width: 96px;
    overflow: hidden;
  }

    .sl-spot-travel-article__thumbnail__img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 150% auto;
    }

    .sl-spot-travel-article__details__breadcrumbs {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
    }

      .sl-spot-travel-article__details__breadcrumb {
        color: color('gray', 55);
        font-family: $product-font-linear;

        &:not(:first-child) {
          position: relative;
          margin-left: 10px;
          padding-left: 10px;

          &:before {
            content: "•";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-3px, -50%);
            font-size: 10px;
            font-weight: 500;
          }
        }
      }

        .sl-spot-travel-article__details__breadcrumb__link {
          color: color('gray', 55);
          font-family: $product-font-linear;
          font-size: 10px;
          font-weight: 500;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 1px;
          line-height: 14px;
        }

      .sl-spot-travel-article__details__title {
        color: color('gray', 90);
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        margin: 0;

        > a {
          text-decoration: none;
          color: inherit;
        }
      }

        .sl-spot-travel-article__details__subtitle {
          color: color('gray', 80);
          font-size: 16px;
          line-height: 20px;
          font-weight: 300;
          margin: 0 0 14px 0;
        }

        .sl-spot-travel-article__details__link {
          color: rgb(0, 88, 182);
          font-size: 16px;
          text-decoration: none;
          font-weight: 600;
        }

@media (max-width: $large-mobile) {
  .sl-spot-travel-article__details__subtitle {
    font-size: 15px;
  }

  .sl-spot-travel-article__details__subtitle,
  .sl-spot-travel-article__details__link {
    display: none;
  }

  .sl-spot-travel-article__details__breadcrumb__link:not(:first-child) {
    display: none;
  }

  .sl-spot-travel-info__content__article {
    padding: 24px 16px;
  }
}
