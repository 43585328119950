@import '../../styles/variables';

@keyframes swell-blink {
  50% {
    opacity: 0.0;
  }
}

.alert {
  background-color: rgb(184,39,235);
  background-image: linear-gradient(90deg, rgba(184,39,235,1) 0%, rgba(133,39,166,1) 100%);
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.action {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.close {
  color: $white;
}

.link {
  align-content: center;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  text-align: left;
  text-decoration: none;

  &::before {
    background-image: linear-gradient(270deg, rgba(184,39,235,1) 0%, rgba(133,39,166,1) 100%);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 200ms ease-in-out;
    width: 100%;
    z-index: 1;
  }

  &:hover,
  &:focus-visible {
    color: $white;

    &::before {
      opacity: 1;
    }

    .locationText,
    .summary,
    .tag,
    .title,
    .updated {
      color: $white;
    }

    .thumbnailImg {
      transform: scale(1.08);
    }
  }
}

.twentyFt {
  align-items: stretch;
  background-color: $product-body;
  background-image: none;
  flex-direction: row;

  .thumbnail {
    order: 3;
    margin-right: 20px;
  }

  .twentyFtLogo {
    order: 2;
  }

  .details {
    order: 1;
    padding-left: 0;
    padding-right: 20px;
  }

  .link {
    &::before {
      background-color: $accent-primary;
      background-image: none;
    }
  }

}

.twentyFtLogo {
  width: 80px;
  padding-right: 20px;
  position: relative;
  z-index: 3;

  path {
    fill: $collinsStache;
  }

  @media(min-width: $large-mobile) {
    width: 100px;
  }

  @media(min-width: $tablet) {
    width: 120px;
  }
}

.twentyFtLogoSvg {
  width: 100%;
}

.details {
  padding-left: 20px;
  position: relative;
  width: calc(100% - 80px);
  z-index: 3;

  @media(min-width: $large-mobile) {
    height: 120px;
    width: calc(100% - 120px);
  }
}

.detailsTop {
  align-items: center;
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}

.tag {
  color: $white;
  text-transform: uppercase;
}

.updated {
  color: $white;
  font-size: 12px;
  padding-left: 16px;
}

.title {
  color: $white;
}

.summary {
  color: $white;
}

.locations {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
}

.location {
  padding: 0;

  &:last-child {
    .locationText {
      &:after {
        content: '';
      }
    }
  }
}

.locationText {
  color: $white;

  &:after {
    content: ' • ';
    padding: 0 5px 0 2px;
  }
}

.thumbnail {
  border-radius: 8px;
  display: block;
  height: 80px;
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 80px;
  z-index: 2;

  @media(min-width: $large-mobile) {
    height: 120px;
    width: 120px;
  }

  @media(min-width: $tablet) {
    width: 214px;
  }
}

.thumbnailImg {
  background-position: center center;
  background-size: cover;
  height: 80px;
  position: relative;
  transition: transform 600ms ease-in-out;
  width: 100%;

  @media(min-width: $large-mobile) {
    height: 120px;
  }

  @media(min-width: $tablet) {
    height: 100%;
    padding-bottom: 0;
  }
}

.live {
  height: 32px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-31px, -16px) scale(70%);
  width: 64px;

  @media(min-width: $large-mobile) {
    transform: translate(-31px, -16px) scale(85%);
  }

  @media(min-width: $tablet) {
    transform: translate(-31px, -16px) scale(100%);
  }
}

.liveDot {
  animation: swell-blink 1s step-start 0s infinite;
  fill: $live;
}


