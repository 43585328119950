@use 'sass:math';
@import '../../../../styles/variables';

.notificaitonBarController {
  :global {
    .quiver-notification {
      box-sizing: border-box;
      position: relative;
      text-align: center;

      .MuiAlert-message {
        width: 100%;
      }
    }

    .quiver-notification__tag-line {
      display: block;
      padding: 6px 8px;
      font-family: $source-sans-pro;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.8px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &__full-text {
        display: inline;
      }
    }

    .quiver-notification__cta {
      padding-top: 9px;
      padding-bottom: 7px;
      font-family: $product-font-linear;
      font-size: 12px;
      letter-spacing: 1.3px;
    }

    .quiver-notification--small {
      vertical-align: middle;
      margin: 0 20px;

      .quiver-notification__cta {
        display: block;
        padding: 8px 12px 7px 15px;
      }
    }

    .quiver-notification--large {
      width: 100%;

      .quiver-notification__cta {
        padding-top: 4px;
      }
    }

    .quiver-notification--warning-notification {
      width: 100%;
      padding: 22px 0;
      display: block;
      font-family: $source-sans-pro;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.8px;
      color: color('white');
      text-decoration: none;
    }

    @media (max-width: math.div($desktopSm, 1.1)) {
      .quiver-notification--small {
        display: none;
      }

      .quiver-notification__tag-line {
        &__full-text {
          display: inline;
        }
      }
    }

    @media (max-width: math.div($desktopSm, 2)) {
      .quiver-notification__tag-line {
        &__full-text {
          display: none;
        }
      }

      .quiver-notification--warning-notification {
        span {
          display: none;
        }
      }
    }
  }
}
