.sl-forecast-table-controls {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.sl-forecast-table-controls__expand-collapse {
  font-family: $source-sans-pro;
  color: color('dark-blue', 10);

  &:hover {
    text-decoration: underline;
  }
}
