.sl-premium-analysis {
  background-color: color('light-blue', 5);
  padding-top: 16px;
  @media (min-width: $tabletLg) {
    padding-top: 24px;
  }
  &__right {
    display: none;
    @media (min-width: $tabletLg) {
      display: block;
      background: color('white');
      height: 600px;
      margin-bottom: 20px;
      padding: 10px;
      box-shadow: 0 1px 2px 0 color('gray', 30);
    }
  }

  .quiver-forecast-article-card {
    background: color('white');
    margin-bottom: 8px;
    @media (min-width: $tabletLg) {
      margin-bottom: 20px;
    }
  }

  .quiver-forecast-article-card__body {
    img {
      height: auto !important;
    }
    div[class^='jwplayer'],
    div[class*=' jwplayer'] {
      width: 100% !important;
      height: 100% !important;
      padding-bottom: 56.25%;
    }
    .jwplayer.jw-stretch-uniform video {
      object-fit: cover;
    }
  }

  .quiver-base-content-card h2 {
    font-weight: 400;
  }

  .quiver-page-rail--left {
    margin-bottom: 20px;
  }

  .quiver-aspect-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .quiver-spinner {
    margin: 30px calc(50% - 16px);
    height: 32px;
    width: 32px;
  }

  .sl-article__ig,
  .twitter-tweet,
  blockquote {
    margin: 8px auto 32px !important;
    width: calc(100% - 32px);
    padding: 0 16px;
    iframe {
      margin: 0 auto !important;
    }
  }

  .twitter-tweet {
    width: 550px !important;
  }
}

.sl-premium-analysis__message {
  padding: 24px;
  background: color('white');
  box-shadow: 0 1px 2px 0 color('gray', 30);
  .quiver-alert {
    padding: 50px 20px;
    text-align: center;
    a {
      font-weight: 600;
      color: color('light-blue', 70);
    }
  }
}
