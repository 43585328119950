@import '../../../../../../styles/variables';

.notificationBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $product-base;
  padding: 16px;
}

.notificationContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: $product-light;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.06);

  :global(.MuiAlert-message) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 16px;
    padding: 0;

    h6 {
      font-weight: 600;
    }
  }

  @media (min-width: $tablet) {
    gap: 32px;
    text-align: center;

    :global(.MuiAlert-message) {
      flex-direction: row;
      gap: 32px;
    }
  }
}

.notificationContentWithButton {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: $product-light;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.06);

  :global(.MuiAlert-message) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 16px;
    padding: 0;

    h6 {
      font-weight: 600;
    }
  }

  @media (min-width: $tablet) {
    gap: 32px;
    text-align: center;

    :global(.MuiAlert-message) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
}

.button {
  background-color: $product-secondary;
  color: $product-base;
  border-radius: 20px;
  padding: 8px 24px;
  margin-top: 10px;

  &:hover,
  &:focus {
    background-color: $product-secondary;
    color: $product-base;
  }

  span {
    &:hover,
    &:focus {
      color: $product-base;
    }
  }

  width: 100%;
  text-align: center;

  @media (min-width: $tablet) {
    width: auto;
    text-align: left;
  }
}

.buttonText {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.link {
  color: $product-secondary;

  h6 {
    &:hover,
    &:focus {
      color: $product-secondary;
    }
  }
}
