/**
The following CSS classes are globally available for usage throughout the app
Example for "Very Poor" conditions:
sl-conditions-color--very-poor-background
sl-conditions-color--very-poor-before-background
sl-conditions-color--very-poor-border
sl-conditions-color--very-poor-color
sl-conditions-color--very-poor-fill
sl-conditions-color--very-poor-color
**/

@import './variables';

$conditions: (
  "very-poor": var(--conditionsVeryPoor),
  "poor": var(--conditionsPoor),
  "poor-to-fair": var(--conditionsPoorToFair),
  "fair": var(--conditionsFair),
  "fair-to-good": var(--conditionsFairToGood),
  "good": var(--conditionsGood),
  "epic": var(--conditionsEpic),
  "none": var(--conditionsNone),
);

@each $name, $cssVariable in $conditions {
  .sl-conditions-color--#{$name} {
    &-background {
      background-color: $cssVariable !important;
    }

    &-before-background {
      &::before {
        background-color: $cssVariable !important;
      }
    }

    &-border {
      border-color: $cssVariable !important;
    }

    &-color {
      color: $cssVariable !important;
    }

    &-fill {
      fill: $cssVariable !important;
    }
  }
}

// Overrides CSS vars set at in @wavetrak/theme to the legacy theme values in src/pages/_App.tsx
.sl-conditions-color--traffic-light {
  --conditionsVeryPoor: #{$conditionsVeryPoorTrafficLight};
  --conditionsPoor: #{$conditionsPoorTrafficLight};
  --conditionsPoorToFair: #{$conditionsPoorToFairTrafficLight};
  --conditionsFair: #{$conditionsFairTrafficLight};
  --conditionsFairToGood: #{$conditionsFairToGoodTrafficLight};
  --conditionsGood: #{$conditionsGoodTrafficLight};
  --conditionsEpic: #{$conditionsEpicTrafficLight};
  --conditionsNone: #{$conditionsNoneTrafficLight};
}

@function conditionColor($condition) {
  @return map-get($conditionColorMap, $condition);
}

$__sl-condition-color-map: (
  'very-poor': (
    default: var(--conditionsVeryPoor),
    dark: var(--conditionsVeryPoor),
  ),
  'poor': (
    default: var(--conditionsPoor),
    dark: var(--conditionsPoor),
  ),
  'poor-to-fair': (
    default: var(--conditionsPoorToFair),
    dark: var(--conditionsPoorToFair),
  ),
  'fair': (
    default: var(--conditionsFair),
    dark: var(--conditionsFair),
  ),
  'fair-to-good': (
    default: var(--conditionsFairToGood),
    dark: var(--conditionsFairToGood),
  ),
  'good': (
    default: var(--conditionsGood),
    dark: var(--conditionsGood),
  ),
  'epic': (
    default: var(--conditionsEpic),
    dark: var(--conditionsEpic),
  ),
  'none': (
    default: var(--conditionsNone),
    dark: var(--conditionsNone),
  ),
);
