@import '../../../../../../styles/_variables.scss';

.baseMenuSection {
  &:last-of-type {
    .baseMenuDivider {
      display: none;
    }
  }
}

.baseMenuDivider {
  border-top: 1px solid $grayLight;
  margin: 10px auto;
  width: calc(100% - 38px);
}

.linkContainer {
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
