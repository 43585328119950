// @use 'sass:map';
@import '../../styles/_variables.scss';

.favorite {
  box-sizing: border-box;
  line-height: calc($favorites-bar-height - 16px);
  vertical-align: text-top;

  background: $white;
  border-radius: 30px;
  color: $textPrimary;
  display: inline-block;
  font-size: 12px;
  outline: none;
  padding: 0 18px 0 10px;
  text-decoration: none;
  transition: background 250ms ease 0s;
  vertical-align: top;

  &:hover,
  &:focus-visible {
    background-color: $grayLight;

    .name,
    .height,
    .units {
      color: $textPrimary;
    }
  }
}

.favorite--disabled {
  pointer-events: none;
}

.wrapper {
  display: inline-block;
  white-space: nowrap;
  padding: 0 5px 0 3px;
  line-height: 100%;
  position: relative;
}

.wrapperTop {
  display: inline-block;
}

.wrapperBottom {
  display: inline-block;
}

.name {
  letter-spacing: -0.25px;
  padding-right: 12px;
}

.units {
  position: absolute;
  bottom: 3px;
  font-size: 11px;
  line-height: 11px;
  padding-left: 1px;
  text-transform: lowercase;
}

.condition {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0px 2px;
  border-radius: 50%;
  white-space: nowrap;
  box-sizing: border-box;
}

.favorite--large {
  padding: 15px 0 19px;
  position: relative;
  vertical-align: initial;

  .link {
    font-size: 16px;
    vertical-align: middle;
    height: 100%;
    padding: 0 22px;
  }

  .wrapper {
    top: 50%;
    transform: translateY(-50%);
  }

  .wrapperTop {
    display: block;

    svg {
      vertical-align: bottom;
    }
  }

  .name {
    padding-right: 8px;
  }

  .wrapperBottom {
    display: block;
    padding-top: 6px;
  }

  .height {
    padding-left: 16px;
    vertical-align: middle;
  }

  .units {
    top: 29px;
  }
}

.favorite--mobile {
  padding: 0;

  .link {
    padding: 5px 0;
    width: 100%;
    border-bottom: 1px solid color('light-blue', 5);
    font-size: 21px;
    line-height: 38px;
  }

  .wrapper {
    width: 100%;
    padding: 0;
    top: 0;
    transform: translateY(0);
  }

  .wrapperTop {
    color: $textPrimary;
    max-width: 60%;
    overflow: hidden;
    padding: 10px 20px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;

    svg {
      vertical-align: middle;
      width: 24px;
      height: 16px;

      &.quiver-multi-cam-icon {
        width: 17px;
        margin-left: 5px;
      }
    }
  }

  .name {
    padding-right: 4px;
  }

  .wrapperBottom {
    padding: 0 20px 7px;
    position: relative;
  }

  .height {
    position: absolute;
    top: -26px;
    right: 26px;
  }

  .units {
    position: absolute;
    top: -30px;
    right: 20px;
  }
}
