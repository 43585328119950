@import '../../../../styles/_variables.scss';

.navigationBar {
  background-color: $white;
}

.logoMobile,
.logoDesktop {
  margin: 0 16px;

  path,
  polyline {
    fill: $brandSecondary;
  }
}

.logoMobile {
  display: flex;

  @media (min-width: $desktopSm) {
    display: none;
  }
}

.logoDesktop {
  display: none;

  @media (min-width: $desktopSm) {
    display: flex;
  }
}

.searchButton {
  align-items: center;
  background-color: $grayLight;
  border-radius: 20px;
  border: 2px solid transparent;
  color: $textSecondary;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  font-size: 12px;
  height: 60%;
  justify-content: center;
  letter-spacing: 0.8px;
  outline: none;
  transition: all 250ms ease;
  width: 100%;

  svg {
    height: 12px;
    margin: 2px 4px 0 0;
    transition: transform 250ms ease;
    width: 12px;

    circle,
    path {
      stroke: $textSecondary;
      transition: stroke 250ms ease;
    }
  }

  &:hover,
  &:focus-visible {
    border-color: $gray40;
    color: $textPrimary;

    svg {
      transform: scale(115%);

      circle,
      path {
        stroke: $textPrimary;
      }
    }
  }

  @media (min-width: $tabletLg) {
    font-size: 14px;

    span {
      display: inline-block;
    }

    svg {
      height: 16px;
      margin: 2px 4px 0 0;
      width: 16px;
    }
  }

  @media (min-width: $desktop-large-width) {
    max-width: 1000px;
  }
}

.searchButtonIcon {
  fill: none;
  height: 16px;
  stroke-width: 1.5px;
  stroke: $white;
  width: 17px;
}

.signin {
  color: $textPrimary;
  flex: 0 0 auto;
  font-size: 12px;
  padding: 0px 16px;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    color: $brandPrimary;
  }

  @media (min-width: $tabletLg) {
    font-size: 14px;
  }
}

.content {
  align-content: center;
  align-items: center;
  display: flex;
  height: $navigation-bar-height;
  justify-content: space-between;
  margin: 0px;
  width: 100%;
}

.navigation,
.cta,
.account,
.search {
  flex: 1 1 auto;
}

.account {
  padding: 0;
}

.navigation {
  display: none;
  margin: 0 16px 0 0;
}

.account,
.navigation {
  @media (min-width: $tabletLg) {
    display: flex;
  }
}

.menuWrapper {
  display: none;
}

.search {
  margin: 0;

  @media (min-width: $tabletLg) {
    margin: 0;
  }
}

.siteSearch {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  width: 100%;

  @media (min-width: $desktop-large-width) {
    justify-content: center;
  }
}

.cta {
  display: none;

  @media (min-width: $tabletLg) {
    display: flex;
  }
}

.ctaLink {
  margin-left: 20px;
  white-space: nowrap;
}

.mobile {
  display: block;
}

.mobileContent {
  height: $mobile-navigation-bar-height;
  margin: 0;
  width: 100%;
}

.mobileContainer {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.mobileNav {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 6px;
  width: 100%;

  // begin centering after specific size
  @media (min-width: 340px) {
    justify-content: center;
  }
}


.mobileNavMenu {
  -webkit-overflow-scrolling: touch;
  background-color: $white;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.mobileNavMenuInner {
  -webkit-overflow-scrolling: touch;
  height: calc(100% + 1px);
  overflow-y: scroll;
  width: 100%;

  .sl-base-menu,
  .sl-cams-reports-menu,
  .sl-forecast-menu {
    &:last-child {
      padding-bottom: 180px;
    }
  }
}

.mobileNavMenuClose {
  align-items: center;
  border-bottom: 1px solid $grayLight;
  color: $textPrimary;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  line-height: 38px;
  margin: 0 8px;
  padding: 5px 0px;
  text-align: right;
  text-decoration: none;
}

.mobileNavMenuCloseTitle {
  font-size: 18px;

  &:hover,
  &:focus-visible {
    color: $textPrimary;
    text-decoration: none;
  }
}

.mobile,
.mobileNavMenu,
.mobileNavMenuClose,
.chevron {
  @media (min-width: $tabletLg) {
    display: none;
  }
}
