@import '../../../../../../styles/_variables.scss';

.baseMenu {
  @media (min-width: $tabletLg) {
    background-color: $white;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.12);
    height: auto;
    min-width: 335px;
    padding: 10px 0;
    position: absolute;
    top: calc(100% - 2px);
    z-index: 100;
  }
  a {
    &:hover * {
      color: inherit;
    }
  }
}

.baseMenu--open-right {
  @media (min-width: $tabletLg) {
    left: auto;
    right: 0;
    top: calc(100% - 10px);
  }
}
