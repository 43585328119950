@import '../../styles/variables';
@import '../../styles/mixins';

.button {
  all: unset;
  color: $textPrimary;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    color: $blue40;
    outline: revert;
  }
}
