@import '../../styles/_variables.scss';

.header {
  background-color: $background;
}

.headerHidden {
  display: none;
}

.headerError {
  min-height: 200px;
}
