.sl-scroll-control {
  display: none;
  @media(min-width: $large-mobile) {
    display: block;
    position: absolute;
    width: 34px;
    text-align: center;
    cursor: pointer;
    height: 50px;
    box-shadow: 0px 0px 2px 0px rgba(169,169,169,1);
    background-color: color('white');
    z-index: 9999;
    &:hover {
      background-color: color('light-blue', 5);
    }
    svg {
      position: absolute;
      top: 40%;
      stroke: $gray-blue;
    }
    &--tall {
      height: 40px;
      padding-top: 43px;
      top: 80px;
      svg {
        position: absolute;
        top: 45%;
      }
    }
    &--left {
      left: 30px;
    }
    &--right {
      right: 30px;
    }
  }
}
