@import '../../../../../../styles/_variables.scss';

.recentlyVisited {
  margin: 8px 0 0;
  padding: 0 16px;

  @media(min-width: $tabletLg) {
    background-color: $background;
    border: none;
    width: 208px;
  }
}

.header {
  margin: 20px 0 0.5em;
  width: 100%;
}

.chevron {
  align-self: center;
  stroke-width: 1px;
  stroke: $textPrimary;
  transform: rotate(90deg);
  width: 14px;

  @media(min-width: $tabletLg) {
    display: none;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;

  @media(min-width: $tabletLg) {
    cursor: default; // button is no longer a header toggle
    pointer-events: none;
  }
}

.header--open {
  .buttonWrapper {
    .chevron {
      transform: rotate(-90deg);
    }
  }
}

.list--hidden {
  display: none;

  @media(min-width: $tabletLg) {
    display: block;
  }
}

.signin {
  color: $textPrimary;
  font-size: 14px;
  line-height: 18px;
  padding: 16px 0;

  @media(min-width: $tabletLg) {
    padding: 0;
  }
}

.link {
  color: $brandPrimary;
  font-weight: 600;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

.staticLinks {
  display: flex;
  flex-direction: column;

  @media(min-width: $tabletLg) {
    margin-top: 16px;
  }
}

.staticLink {
  border-top: 1px solid $grayLight;
  color: $textPrimary;
  font-size: 18px;
  line-height: 48px;
  outline: none;
  text-decoration: none;

  &:last-of-type {
    border-bottom: 1px solid $grayLight;
    margin-bottom: -1px;
  }

  @media(min-width: $tabletLg) {
    border: none !important;
    font-size: 14px;
    line-height: 1;
    margin-top: 16px;

    &:hover,
    &:focus-visible {
      color: $brandPrimary;
      cursor: pointer;
    }
  }
}
