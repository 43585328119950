@import '../../styles/variables';

.adBlock {
  height: 100%;
  margin: 0;
  text-align: center;
  width: 100%;

  h5 {
    margin-bottom: 8px;
  }

  @media (min-width: $tabletLg) {
    height: auto;
    width: auto;
  }
}

.imageContainer {
  margin: 40px;
  text-align: center;
}

.image {
  height: 87px;
  width: 87px;
}

.nowrap {
  white-space: nowrap;
}

.button {
  width: 100%;
  margin-bottom: 1em;
}

.heading {
  margin-bottom: 6px;
}

.modal {
  background-color: $product-base;
  color: $product-base;
  @media (min-width: $large-mobile) {
    background-color: rgb(0, 0, 0, 0.4);
  }
}

.modalContent {
  background-color: $product-base;
  border-radius: 8px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  left: 50%;
  padding: 40px 56px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow-y: auto;
  width: 90%;
  max-width: 550px;
  &:focus-visible {
    outline: none;
  }

  @media (min-width: $tabletLg) {
    width: auto;
  }
}

.footerText {
  color: $product-body;
}
