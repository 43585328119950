@import '../../styles/variables';

.body {
  position: relative;

  @media (min-width: $tablet) {
    background: url('https://prod-subs.cdn-surfline.com/geo_assets/404.jpg') center left;
    background-size: cover;
    min-height: 720px;
    padding-bottom: 56.25%;
  }

  @media (min-width: $desktop) {
    min-height: 1024px;
  }
}

.container {
  background: $white;
  margin: 0 auto;
  max-width: 410px;
  padding: 30px;

  @media (min-width: $tablet) {
    background: transparent;
    padding-left: 100px;
    position: absolute;
    max-width: 800px;
    top: 60%;
  }
}

.containerTitle {
  text-align: center;

  @media (min-width: $tablet) {
    text-align: left;
    color: $white;
    font-size: 52px;
    line-height: 52px;
    letter-spacing: 2.6px;
    margin: 10px 0 0 0;
  }
}

.containerParagraph {
  color: $brandSecondary;
  margin: 2em 0;
  text-align: center;

  @media (min-width: $tablet) {
    color: $grayLight;
    margin: 1em 0;
    text-align: left;
  }
}

.containerParagraphDesktop {
  display: none;

  @media (min-width: $tablet) {
    color: $grayLight;
    text-align: left;
    display: inline-block;
  }
}

.containerParagraphLink {
  color: color('light-blue', 50);
  cursor: pointer;
}

.imageContainer {
  background: $white;
  margin: 0 auto;
  position: relative;
  text-align: center;
  padding-bottom: 64.35%;
  width: 100%;

  @media (min-width: $tablet) {
    display: none;
  }
}

.containerImage {
  width: 100%;
  margin-top: 35px;
}

.button {
  margin: 2em 0;
  width: 100%;

  @media (min-width: $tablet) {
    display: none;
  }
}
