@import '../../styles/variables';

.banner {
  display: block;

  :global {
    .MuiDrawer-paper {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }
}

.actions {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:first-child {
    margin-bottom: 20px;
  }
}

.iconWrapper {
  border: 1px solid transparent;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  path {
    fill: $brandSecondary;
  }
}

.surflineWrapper {
  border-color: $product-light;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
}

.title {
  margin: 0 10px;
  flex-grow: 1;
}

.button {
  width: 120px;
}

.phoneImageWrapper {
  text-align: center;
  font-size: 0;
}

.phoneImage {
  display: block;
  max-width: 100%;
}
