@import '../_mixins.scss';

.sl-view-forecast {
  width: calc(100% - 40px);
  margin: 50px 20px;
  border: 1px solid color('gray', 30);
  @media(min-width: $large-mobile) {
    margin: 50px auto;
    max-width: 450px;
  }
  @media(min-width: $tablet) {
    max-width: 640px;
  }
  &__container {
    padding: 20px;
    &__link {
      display: inline-block;
      @media(min-width: $tablet) {
        display: block;
        text-align: right;
        margin-top: -38px;
      }
      a {
        @include sl-h6;
        font-size: 16px;
        color: color('light-blue', 50);
        text-decoration: none;
        &:hover {
          color: color('light-blue', 70);
        }
        @media(min-width: $tablet) {
          font-size: 16px;
        }
      }
    }
    h5 {
      @include sl-h5;
      padding-top: 0;
      font-size: 18px;
      text-transform: uppercase;
    }
    p {
      @include paragraph;
      margin-top: 10px;
      color: color('gray', 90);
      @media(min-width: $tablet) {
        margin-top: 0;
        max-width: 400px;
      }
    }
    span {
      display: block;
      @media(min-width: $tablet) {
        display: inline-block;
      }
    }
  }
}
