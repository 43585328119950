.sl-swell-height-legend {
  line-height: 16px;
}

.sl-swell-height-legend__class {
  display: inline-block;
  vertical-align: bottom;
  height: 16px;

  &--single {
    width: 8px;
  }

  &--double {
    width: 16px;
  }

  &--triple {
    width: 24px;
  }

  @each $height, $colors in $swell-height-colors-ft {
    &--#{$height}-ft {
      background-color: map-get($colors, background-color);
    }
  }

  @each $height, $colors in $swell-height-colors-m {
    &--#{$height}-m {
      background-color: map-get($colors, background-color);
    }
  }
}

.sl-swell-height-legend__label {
  position: relative;
  font-family: $product-font-linear;
  font-size: 12px;
  color: color('white');
  text-align: right;
  line-height: 16px;
}

@media(min-width: $small-mobile) {
  .sl-swell-height-legend__class {
    &--single {
      width: 10px;
    }
    &--double {
      width: 20px;
    }
    &--triple {
      width: 28px;
    }
  }
}

@media(min-width: $tabletLg) {
  .sl-swell-height-legend__class {
    &--single {
      width: 13px;
    }
    &--double {
      width: 26px;
    }
    &--triple {
      width: 39px;
    }
  }

  .sl-swell-height-legend__label {
    font-size: 16px;
    line-height: 14px;
  }
}
